<script setup>
defineEmits(['close'])

const toast = useToast()
const { t } = useI18n()
const router = useRouter()
let emailScript

async function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(script) // 스크립트 요소를 반환
    }
    script.onerror = reject
    script.type = 'text/javascript'
    document.head.appendChild(script)
  })
}
function removeScript(scriptElement) {
  // 스크립트 요소를 head에서 제거
  if (scriptElement.parentNode)
    scriptElement.parentNode.removeChild(scriptElement)
}

async function sendEmailUnsubscription() {
  // https://www.emailjs.com/
  const serviceID = 'default_service'
  const templateID = 'template_33hfiqx'

  try {
    const user_response = await select_user('name, email, idx')
    emailScript = await loadScript('https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js')
    const formData = {
      name: user_response.data.name,
      email: user_response.data.email,
    }
    emailjs.init('_XEFzM4wULFRPbV6f')
    emailjs.send(serviceID, templateID, formData)
  }
  finally {
    if (emailScript)
      removeScript(emailScript)
  }
}

async function unsubscriptionClick() {
  const { error } = await unsubscription()

  if (error) {
    toast.add({ title: t('에러.에러발생'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
    return
  }
  sendEmailUnsubscription()
  toast.add({ title: t('setting.구독.구독해지성공'), color: 'green', timeout: 2000 })
  router.push('/loading')
}
</script>

<template>
  <div class="modal">
    <div class="DeleteAccount h-[289px] w-[494px] inline-flex items-center justify-center rounded-[10px] bg-white">
      <div
        class="Frame211 inline-flex shrink grow basis-0 flex-col items-start self-stretch justify-start pb-[15px] pt-5"
      >
        <div
          class="Frame216 h-14 inline-flex items-start self-stretch justify-between border-b border-stone-300 px-[30px]"
        >
          <div class="text-[25px] font-normal font-['Pretendard'] text-black">
            {{ $t('setting.구독.정말해지하시겠어요') }}
          </div>
          <button id="close" @click="$emit('close')">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M16.5669 3.93306C16.811 4.17714 16.811 4.57286 16.5669 4.81694L5.31694 16.0669C5.07286 16.311 4.67714 16.311 4.43306 16.0669C4.18898 15.8229 4.18898 15.4271 4.43306 15.1831L15.6831 3.93306C15.9271 3.68898 16.3229 3.68898 16.5669 3.93306Z"
                fill="#331E08"
              />
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M4.43306 3.93306C4.67714 3.68898 5.07286 3.68898 5.31694 3.93306L16.5669 15.1831C16.811 15.4271 16.811 15.8229 16.5669 16.0669C16.3229 16.311 15.9271 16.311 15.6831 16.0669L4.43306 4.81694C4.18898 4.57286 4.18898 4.17714 4.43306 3.93306Z"
                fill="#331E08"
              />
            </svg>
          </button>
        </div>
        <div
          class="Frame227 flex shrink grow basis-0 flex-col items-start self-stretch justify-start gap-5 px-[30px] py-2.5"
        >
          <div class="NewPassword flex shrink grow basis-0 flex-col items-start self-stretch justify-start gap-2.5">
            <div class="Label inline-flex items-start self-stretch justify-center gap-2.5 py-[3px]">
              <div
                class="shrink grow basis-0 self-stretch text-sm font-normal leading-[21px] font-['Pretendard'] text-zinc-900"
              >
                {{ $t('setting.구독.아쉬워요구독을해지하면다양한Pro의혜택과기회를잃게됩니다') }} <br>
                {{ $t('setting.구독.구독유지시새로운기능과업데이트를가장먼저경험하실수있어요') }}

                <br><br>

                {{ $t('setting.구독.더많은혜택을누릴수있는기회를놓치지마세요') }}
              </div>
            </div>
          </div>
        </div>
        <div class="Frame228 inline-flex items-end self-stretch justify-end gap-[45px] px-[30px] py-[15px]">
          <button class="text-[15px] font-normal font-['Pretendard'] text-black" @click="unsubscriptionClick">
            {{ $t('setting.구독.구독해지') }}
          </button>
          <button class="text-[15px] font-normal font-['Pretendard'] text-orange-400" @click="$emit('close')">
            {{ $t('setting.구독.취소') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000001;
}

.Frame216 {
  border-bottom: 1px solid #CECECE;
}
</style>
