<script setup>
defineEmits(['close'])

const toast = useToast()
const { t } = useI18n()

async function delete_user_button_click() {
  const { error } = await delete_account()

  if (error) {
    toast.add({ title: t('setting.회원탈퇴.알림전송실패'), description: t('setting.회원탈퇴.알림60초마다한번만요청할수있습니다'), color: 'red', timeout: 2000 })
    return
  }
  toast.add({ title: t('setting.회원탈퇴.알림탈퇴완료'), description: t('setting.회원탈퇴.알림그동안서비스를이용해주셔서감사합니다'), color: 'green', timeout: 2000 })
  try {
    logout()
  }
  catch (error) {
    const toast = useToast()
    toast.add({ title: t('에러.에러발생'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
  }
}
</script>

<template>
  <div class="modal">
    <div class="DeleteAccount h-[289px] w-[494px] inline-flex items-center justify-center rounded-[10px] bg-white">
      <div
        class="Frame211 inline-flex shrink grow basis-0 flex-col items-start self-stretch justify-start pb-[15px] pt-5"
      >
        <div
          class="Frame216 h-14 inline-flex items-start self-stretch justify-between border-b border-stone-300 px-[30px]"
        >
          <div class="text-[25px] font-normal font-['Pretendard'] text-black">
            {{ $t('setting.회원탈퇴.회원탈퇴') }}
          </div>
          <button id="close" @click="$emit('close')">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M16.5669 3.93306C16.811 4.17714 16.811 4.57286 16.5669 4.81694L5.31694 16.0669C5.07286 16.311 4.67714 16.311 4.43306 16.0669C4.18898 15.8229 4.18898 15.4271 4.43306 15.1831L15.6831 3.93306C15.9271 3.68898 16.3229 3.68898 16.5669 3.93306Z"
                fill="#331E08"
              />
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M4.43306 3.93306C4.67714 3.68898 5.07286 3.68898 5.31694 3.93306L16.5669 15.1831C16.811 15.4271 16.811 15.8229 16.5669 16.0669C16.3229 16.311 15.9271 16.311 15.6831 16.0669L4.43306 4.81694C4.18898 4.57286 4.18898 4.17714 4.43306 3.93306Z"
                fill="#331E08"
              />
            </svg>
          </button>
        </div>
        <div
          class="Frame227 flex shrink grow basis-0 flex-col items-start self-stretch justify-start gap-5 px-[30px] py-2.5"
        >
          <div class="NewPassword flex shrink grow basis-0 flex-col items-start self-stretch justify-start gap-2.5">
            <div class="Label inline-flex items-start self-stretch justify-center gap-2.5 py-[3px]">
              <div
                class="shrink grow basis-0 self-stretch text-sm font-normal leading-[21px] font-['Pretendard'] text-zinc-900"
              >
                {{ $t('setting.회원탈퇴.계정이영구삭제됩니다') }} <br><br>{{
                  $t('setting.회원탈퇴.탈퇴후에는아이디와데이터는삭제되며복원할수없습니다게시판형서비스에남아있는게시글은탈퇴후삭제할수없습니다') }}
              </div>
            </div>
          </div>
        </div>
        <div class="Frame228 inline-flex items-end self-stretch justify-end gap-[45px] px-[30px] py-[15px]">
          <button class="text-[15px] font-normal font-['Pretendard'] text-black" @click="$emit('close')">
            {{
              $t('setting.회원탈퇴.취소') }}
          </button>
          <button class="text-[15px] font-normal font-['Pretendard'] text-orange-400" @click="delete_user_button_click">
            {{
              $t('setting.회원탈퇴.회원탈퇴') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000001;
}

.Frame216 {
  border-bottom: 1px solid #CECECE;
}
</style>
