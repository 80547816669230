<script setup lang="ts">
const head = useLocaleHead({ // https://v8.i18n.nuxtjs.org/guide/seo#setup
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
</script>

<template>
  <!-- 로그인 후 navigation bar 왼쪽 배치 -->
  <Html :lang="(head.htmlAttrs as any).lang" :dir="(head.htmlAttrs as any).dir">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <main class="h-screen w-full flex inline-flex">
        <DashboardNavBar />
        <slot />
      </main>
    </Body>
  </Html>
</template>
