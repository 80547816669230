<script setup lang="ts">
const isUpgradePlanModal = ref(false)

const unsubscriptionModal = ref(false)
const plan_response = await get_plan()
const plan = ref('')
const { t } = useI18n()
const toast = useToast()

const payment_history = ref([])

async function initPlan(planName: string) {
  plan.value = planName

  if (plan.value === 'pro') {
    const { data, error } = await get_payment_history()
    if (error)
      toast.add({ title: t('에러.결제내역불러오기실패'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
    else
      payment_history.value = data === null ? [] : data
  }
}

if (plan_response.error)
  toast.add({ title: t('에러.플랜불러오기실패'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
else
  initPlan(plan_response.data)
</script>

<template>
  <DashboardUpgradePlan v-if="isUpgradePlanModal" @close="isUpgradePlanModal = false" />
  <DashboardSettingModalUnsubscription v-if="unsubscriptionModal" @close="unsubscriptionModal = false" />
  <div
    class="Main h-full w-full inline-flex flex-col items-end justify-start gap-[30px] overflow-y-auto px-[100px] py-[30px]"
  >
    <div class="Plancomponent h-[150px] flex flex-col items-start self-stretch justify-start">
      <div
        class="Plan h-[150px] w-[864px] flex flex-col items-start self-stretch justify-start border border-orange-400 rounded-xl bg-white p-6 shadow"
      >
        <div class="Inner inline-flex shrink grow basis-0 items-start self-stretch justify-between gap-20">
          <div class="Text w-[205px] inline-flex flex-col items-start self-stretch justify-between gap-6">
            <div class="Frame188 h-[69px] flex flex-col items-start self-stretch justify-start gap-[15px]">
              <div class="self-stretch text-xl font-bold text-neutral-800">
                {{ $t('setting.구독.플랜') }}
              </div>
              <div class="self-stretch text-[25px] font-bold text-neutral-600">
                {{ $t(`setting.구독.${plan}`) }}
              </div>
              <div v-if="payment_history.length !== 0" class="2023918 w-[373px] text-[13px] font-medium font-['Pretendard'] text-neutral-600">
                {{ $t('setting.구독.다음결제일은000입니다1') }}{{ (payment_history[0] as any).next_payment_date }}{{ $t('setting.구독.다음결제일은000입니다2') }}
              </div>
            </div>
          </div>
          <button
            v-if="plan === 'free'"
            class="GoPro w-[250px] inline-flex flex-col items-center justify-center gap-[5px] border border-gray-200 rounded bg-white pb-[14.59px] pl-[20.30px] pr-[20.11px] pt-[11px] shadow"
            @click="isUpgradePlanModal = true"
          >
            <div class="GoPro h-[17px] w-full text-center">
              <span class="GoTxt">{{ $t('setting.구독.프로') }} </span><span class="ProTxt">{{ $t('setting.구독.업그레이드')
              }}</span>
            </div>
            <div
              class="AddPowerFeaturesForJust5Month h-3.5 w-[209.59px] text-center text-xs font-normal leading-none text-zinc-400"
            >
              {{ $t('setting.구독.업무능률200향상하기') }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <table v-if="plan === 'pro'" class="w-full border-collapse">
      <thead>
        <tr class="border-b border-t border-zinc-300 bg-stone-50">
          <th class="w-[242px] py-4 pl-5 text-left text-sm font-semibold text-neutral-700">
            {{ $t('setting.구독.날짜') }}
          </th>
          <th class="w-[406px] py-4 pl-5 text-left text-sm font-semibold text-neutral-700">
            {{ $t('setting.구독.설명') }}
          </th>
          <th class="w-[406px] py-4 pl-5 text-left text-sm font-semibold text-neutral-700">
            {{ $t('setting.구독.서비스기간') }}
          </th>
          <th class="w-[406px] py-4 pl-5 text-left text-sm font-semibold text-neutral-700">
            {{ $t('setting.구독.결제수단') }}
          </th>
          <th class="w-[406px] py-4 pl-5 text-left text-sm font-semibold text-neutral-700">
            {{ $t('setting.구독.총합계') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in (payment_history as any)" :key="row.date" class="h-[68px]">
          <td class="py-[20px] pl-5 text-left text-sm font-medium text-neutral-700">
            {{ row.date }}
          </td>
          <td class="py-[20px] pl-5 text-left text-sm font-medium text-neutral-700">
            {{ row.service_name }}
          </td>
          <td class="py-[20px] pl-5 text-left text-sm font-medium text-neutral-700">
            {{ row.subscription_start_date }} ~ {{ row.subscription_end_date }}
          </td>
          <td class="py-[20px] pl-5 text-left text-sm font-medium text-neutral-700">
            {{ $t('setting.구독.신용카드') }}
          </td>
          <td class="py-[20px] pl-5 text-left text-sm font-medium text-neutral-700">
            {{ row.total_price }} {{ row.currency }}
          </td>
        </tr>
        <!-- 나머지 행들도 동일한 구조로 반복해서 추가 -->
      </tbody>
    </table>

    <button @click="unsubscriptionModal = true">
      <div v-if="plan === 'pro'" id="cancelPlan">
        <span>{{ $t('setting.구독.해지하기') }}</span>
      </div>
    </button>
  </div>
</template>

<style scoped>
table * {
  color: var(--neutral-black, #383838);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Plan {
  border-radius: 12px;
  border: 1px solid var(--primay, #FE9526);
  background: #FFF;

  /* shadow */
  box-shadow: 0px 2px 10px 0px rgba(240, 135, 24, 0.25), 4px 12px 45px 0px rgba(240, 135, 24, 0.12);
}

.GoTxt {
  color: #0D0C22;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */

}

.ProTxt {
  color: #FE9526;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;

}

.cancelPlan {
  display: flex;
  width: 116px;
  padding: 14px 34px;
  justify-content: flex-end;
  align-items: flex-end;

}

.cancelPlan,
span {
  color: #868484;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}
</style>
