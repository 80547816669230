<script setup>
import { TIMEZONES, getLangueNames, i18nCode2Name } from '~/constants/setting'

const { setLocale, locales } = useI18n()

const languages = getLangueNames()
const timezones = TIMEZONES
const toast = useToast()
const { t } = useI18n()

const deleteUserModal = ref(false)
const userLanguage = ref('')
const userTimezone = ref('')
const userName = ref('')
const userEmail = ref('')
const user_response = await select_user('name, language, timezone, email')

if (user_response.error) {
  toast.add({ title: t('에러.사용자불러오기실패'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
}
else {
  userLanguage.value = i18nCode2Name(user_response.data.language)
  userTimezone.value = user_response.data.timezone
  userName.value = user_response.data.name
  userEmail.value = user_response.data.email
}

const isPwdResetButtonDisabled = ref(false)
const isUserSaveButtonDisabled = ref(false)

watch([userLanguage, userTimezone, userName], () => {
  isUserSaveButtonDisabled.value = false
})

async function PwdResetNotifyOk() {
  // button disable
  isPwdResetButtonDisabled.value = true

  // supabase send link
  const error = await supabaseSendResetPasswordLink(user_response.data.email)

  // send link result notify
  if (error) {
    if (error.message.includes('For security purposes, you can only request this once every') || error.status === 429)
      toast.add({ title: t('setting.계정.알림전송실패'), description: t('setting.계정.알림60초마다한번만요청할수있습니다'), color: 'red', timeout: 2000 })

    else
      toast.add({ title: t('setting.계정.알림전송실패'), description: t('setting.계정.알림반복실패시이메일문의주세요'), color: 'red', timeout: 2000 })
  }
  else {
    toast.add({ title: t('setting.계정.알림이메일전송완료'), color: 'green', timeout: 2000 })
  }
}

function PwdResetNotify() {
  toast.add({
    title: t('setting.계정.알림비밀번호재설정'),
    description: t('setting.계정.알림등록된메일로재설정링크를보냅니다'),
    color: 'green',
    timeout: 4000,
    actions: [{ variant: 'outline', color: 'primary', label: t('setting.계정.알림받기'), click: PwdResetNotifyOk }, { variant: 'solid', color: 'gray', label: t('setting.계정.알림취소') }],
  })
}

const currentTime = computed(() => {
  if (userTimezone.value) {
    const selectedTimezone = userTimezone.value
    // 형식 옵션을 설정하여 '00:00 PM' 형식으로 시간을 가져옵니다.
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }

    const time = new Date().toLocaleString('en-US', { timeZone: selectedTimezone, ...options })
    return time
  }
  else {
    return '00:00 AM'
  }
})

function findI18nName(locale) {
  if (locale.name === userLanguage.value)
    return true
}

function setLanguage(code) {
  setLocale(code)
}

async function updateUser() {
  const i18nCode = locales.value.find(findI18nName)?.code
  const { error } = await update_user({ language: i18nCode, timezone: userTimezone.value, name: userName.value })
  if (error) {
    toast.add({ title: t('setting.계정.알림저장실패'), description: t('setting.계정.알림반복실패시이메일문의주세요'), color: 'red', timeout: 2000 })
  }
  else {
    toast.add({ title: t('setting.계정.알림저장완료'), color: 'green', timeout: 2000 })
    isUserSaveButtonDisabled.value = true
    setLanguage(i18nCode)
  }
}
</script>

<template>
  <DashboardSettingDeleteUser v-if="deleteUserModal" @close="deleteUserModal = false" />
  <div class="Main h-[716px] w-full flex flex-col items-start gap-[30px] overflow-y-auto px-[100px] py-[30px]">
    <form class="Form flex flex-col items-start justify-center gap-[25px]" @submit.prevent="updateUser">
      <div class="Email h-[73px] w-[423px] inline-flex flex-col items-start justify-center gap-[9px]">
        <div class="Label inline-flex items-start self-stretch justify-start gap-px py-0.5">
          <div class="h-3.5 w-[37.68px] text-sm font-normal leading-[21px] font-['Pretendard'] text-zinc-900">
            {{
              $t('setting.계정.이메일') }}
          </div>
          <UTooltip :text="$t('setting.계정.인증이완료되어변경할수없어요')">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M2.12988 8.12988C2.12988 7.34195 2.28508 6.56174 2.58661 5.83378C2.88813 5.10583 3.33009 4.44439 3.88724 3.88724C4.44439 3.33009 5.10583 2.88813 5.83378 2.58661C6.56174 2.28508 7.34195 2.12988 8.12988 2.12988C8.91781 2.12988 9.69803 2.28508 10.426 2.58661C11.1539 2.88813 11.8154 3.33009 12.3725 3.88724C12.9297 4.44439 13.3716 5.10583 13.6732 5.83378C13.9747 6.56174 14.1299 7.34195 14.1299 8.12988C14.1299 9.72118 13.4977 11.2473 12.3725 12.3725C11.2473 13.4977 9.72118 14.1299 8.12988 14.1299C6.53858 14.1299 5.01246 13.4977 3.88724 12.3725C2.76202 11.2473 2.12988 9.72118 2.12988 8.12988ZM8.79655 7.46322V10.9299H7.46322V7.46322H8.79655ZM8.12988 4.99655C7.86467 4.99655 7.61031 5.10191 7.42278 5.28944C7.23524 5.47698 7.12988 5.73133 7.12988 5.99655C7.12988 6.26177 7.23524 6.51612 7.42278 6.70366C7.61031 6.89119 7.86467 6.99655 8.12988 6.99655H8.13655C8.40177 6.99655 8.65612 6.89119 8.84366 6.70366C9.03119 6.51612 9.13655 6.26177 9.13655 5.99655C9.13655 5.73133 9.03119 5.47698 8.84366 5.28944C8.65612 5.10191 8.40177 4.99655 8.13655 4.99655H8.12988Z"
                fill="#9F9F9F"
              />
            </svg>
          </UTooltip>
        </div>
        <input
          v-model="userEmail"
          class="Input h-[46px] inline-flex items-center self-stretch justify-center border border-gray-300 rounded-lg bg-white py-[11px] pl-[15px] pr-[14.80px] focus:border-orange-500 focus:outline-none"
          type="text" disabled
        >
      </div>

      <div class="Name h-[75px] flex flex-col items-start justify-center gap-2">
        <div class="Label h-[21px] inline-flex items-center self-stretch justify-start pb-1 pr-[385.32px] pt-[3px]">
          <div class="h-3.5 w-[37.68px] text-sm font-normal leading-[21px] text-zinc-900">
            {{ $t('setting.계정.이름') }}
          </div>
        </div>
        <input
          v-model="userName"
          class="Input h-[46px] inline-flex items-center self-stretch justify-center border border-gray-300 rounded-lg bg-white py-[11px] pl-[15px] pr-[14.80px] focus:border-orange-500 focus:outline-none"
          type="text"
        >
      </div>

      <div class="Password flex flex-col items-start justify-end gap-[5px]">
        <div
          class="Label h-[21px] w-[423px] inline-flex items-center justify-start pb-1 pl-[0.50px] pr-[361.50px] pt-[3px]"
        >
          <div class="h-3.5 w-[61px] text-sm font-normal leading-[21px] text-zinc-900">
            {{ $t('setting.계정.패스워드') }}
          </div>
        </div>
        <button
          :disabled="isPwdResetButtonDisabled"
          :class="{ 'border-gray-300 bg-gray-300': isPwdResetButtonDisabled, 'border-orange-300 bg-orange-300': !isPwdResetButtonDisabled }"
          class="BtnSm1 h-[23px] flex flex-col items-center justify-center gap-2.5 border rounded-[5px] bg-opacity-0 p-[10px]"
          @click.prevent="PwdResetNotify"
        >
          <span
            :class="{ 'text-gray-300': isPwdResetButtonDisabled, 'text-orange-300': !isPwdResetButtonDisabled }"
            class="H6 text-center text-[10px] font-semibold leading-normal tracking-tight"
          >
            {{ $t('setting.계정.변경하기') }}
          </span>
        </button>
      </div>
      <div class="Langauge h-[75px] w-[423px] flex flex-col items-start justify-center gap-3 pt-[3px]">
        <div class="h-3.5 w-[63.08px] text-sm font-normal leading-[21px] text-zinc-900">
          {{ $t('setting.계정.언어') }}
        </div>
        <USelect
          v-model="userLanguage" class="w-full" select-class=" ring-gray-300 focus:ring-1 w-full h-[46px]"
          color="orange" :options="languages"
        />
      </div>
      <div class="Timezone h-[83px] w-[423px] flex flex-col items-start justify-center gap-2">
        <div class="Label h-[29px] w-full inline-flex items-center self-stretch justify-start pb-3 pt-[3px]">
          <div class="TimeZone h-3.5 w-full flex justify-between text-sm font-normal leading-[21px] text-zinc-900">
            <span>{{ $t('setting.계정.표준시간대') }} </span> <span>{{ $t('setting.계정.현재시간') }}: {{ currentTime }} </span>
          </div>
        </div>
        <USelect
          v-model="userTimezone" class="w-full" select-class="ring-gray-300 focus:ring-1 w-full h-[46px]"
          color="orange" :options="timezones"
        />
      </div>
      <div class="Actions relative h-11 w-[505px]">
        <button
          class="absolute hover:underline left-[364.50px] top-[14px] text-[13px] font-normal text-zinc-500"
          @click.prevent="deleteUserModal = true"
        >
          {{ $t('setting.계정.계정삭제') }}
        </button>
        <button
          :disabled="isUserSaveButtonDisabled"
          :class="{ 'border-gray-400  bg-gray-400': isUserSaveButtonDisabled, 'border-orange-400 bg-orange-400': !isUserSaveButtonDisabled }"
          class="Button absolute left-[0.50px] top-0 w-[124px] inline-flex items-center justify-center border rounded-[40px] py-[11px] pl-[18px] pr-[17.58px]"
        >
          <div class="text-center text-sm font-normal leading-snug text-white">
            <span>{{ $t('setting.계정.저장하기') }}</span>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
a:hover {
  text-decoration: underline
}
</style>
