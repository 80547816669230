<script setup>
defineEmits(['close'])
const { t } = useI18n()

const selectMenu = ref('account')
const title = ref(t('setting.메뉴.계정'))
const route = useRoute()
const detail = route.query.detail

if (detail === 'subscription') {
  selectMenu.value = 'billing'
  title.value = t('setting.메뉴.구독')
}
else if (detail === 'account') {
  selectMenu.value = 'account'
  title.value = t('setting.메뉴.계정')
}

function handleButtonClick(event, selectMenuName) {
  selectMenu.value = selectMenuName // main content 바꾸기
  title.value = event.target.outerText // 셋팅 타이틀 바꾸기
}
</script>

<template>
  <div class="modal">
    <div class="modal-content overflow-auto">
      <div class="Setting h-[700px] w-[1298px] inline-flex items-center justify-center rounded-[10px] bg-white">
        <div
          class="SettingMenu w-[225px] inline-flex flex-col items-center self-stretch justify-start gap-5 border-r border-stone-300"
        >
          <div
            class="Top h-[86px] w-[225px] inline-flex items-center justify-start pb-[37px] pl-[31.50px] pr-[156.50px] pt-[25px]"
          >
            <div class="text-xl font-bold text-orange-950">
              {{ $t('setting.메뉴.셋팅') }}
            </div>
          </div>
          <div class="Account inline-flex items-center self-stretch justify-between gap-2.5">
            <button
              class="Account h-[30px] flex shrink grow basis-0 items-center justify-start gap-2.5"
              @click="handleButtonClick($event, 'account')"
            >
              <div class="IcnUserIcnSm relative h-[22px] w-[14.66px]" />
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M17.3936 20C18.0031 20 18.5651 19.6713 18.8639 19.1401V19.1401C19.1799 18.5784 19.1452 17.8849 18.6774 17.4417C17.6708 16.488 15.5558 15 12.158 15C8.76633 15 6.75507 16.4827 5.81209 17.4365C5.36745 17.8863 5.35063 18.5647 5.6607 19.1159V19.1159"
                  stroke="#131214" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                />
                <path
                  d="M12.2691 11.1111C14.2328 11.1111 15.8247 9.51924 15.8247 7.55556C15.8247 5.59188 14.2328 4 12.2691 4C10.3054 4 8.71355 5.59188 8.71355 7.55556C8.71355 9.51924 10.3054 11.1111 12.2691 11.1111Z"
                  stroke="#131214" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                />
              </svg>
              <div id="name" class="text-base font-semibold text-orange-950">
                {{ $t('setting.메뉴.계정') }}
              </div>
            </button>
            <div v-if="selectMenu === 'account'" class="Rectangle4 h-[30px] w-[5px] bg-orange-400" />
          </div>
          <div class="Billing inline-flex items-center self-stretch justify-between gap-2.5">
            <button
              class="Account h-[30px] flex shrink grow basis-0 items-center justify-start gap-2.5"
              @click="handleButtonClick($event, 'billing')"
            >
              <div class="IcnUserIcnSm relative h-[22px] w-[14.66px]" />
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M13.9323 13.9368C13.5181 13.9368 13.1823 14.2725 13.1823 14.6868C13.1823 15.101 13.5181 15.4368 13.9323 15.4368V13.9368ZM16.4245 15.4368C16.8387 15.4368 17.1745 15.101 17.1745 14.6868C17.1745 14.2725 16.8387 13.9368 16.4245 13.9368V15.4368ZM4.158 15L4.908 15V15L4.158 15ZM4.158 9L4.908 9V9H4.158ZM4.15799 10.75H20.158V9.25H4.15799V10.75ZM13.9323 15.4368H16.4245V13.9368H13.9323V15.4368ZM3.408 15C3.40799 17.0711 5.08692 18.75 7.15799 18.75V17.25C5.91535 17.25 4.90799 16.2426 4.908 15L3.408 15ZM19.408 15C19.408 16.2426 18.4006 17.25 17.158 17.25V18.75C19.2291 18.75 20.908 17.0711 20.908 15H19.408ZM20.908 9C20.908 6.92893 19.2291 5.25 17.158 5.25V6.75C18.4006 6.75 19.408 7.75736 19.408 9H20.908ZM4.908 9C4.908 7.75736 5.91536 6.75 7.158 6.75V5.25C5.08693 5.25 3.408 6.92893 3.408 9H4.908ZM19.408 9V15H20.908V9H19.408ZM4.908 15L4.908 9L3.408 9L3.408 15L4.908 15ZM7.158 6.75H17.158V5.25H7.158V6.75ZM17.158 17.25H7.15799V18.75H17.158V17.25Z"
                  fill="#131214"
                />
              </svg>
              <div id="name" class="text-base font-semibold text-orange-950">
                {{ $t('setting.메뉴.구독') }}
              </div>
            </button>
            <div v-if="selectMenu === 'billing'" class="Rectangle4 h-[30px] w-[5px] bg-orange-400" />
          </div>
        </div>
        <div
          class="SettingContent inline-flex shrink grow basis-0 flex-col items-center self-stretch justify-start bg-white"
        >
          <div
            class="Top h-[94px] inline-flex items-start self-stretch justify-between gap-[546px] border-b border-orange-400 pr-5 pt-5"
          >
            <div class="Title h-[73px] flex items-center self-stretch justify-center gap-2.5 px-[39px] py-0.5">
              <div class="text-[25px] font-bold text-orange-400">
                {{ title }}
              </div>
            </div>
            <button id="close" @click="$emit('close')">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                  fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.5669 3.93306C16.811 4.17714 16.811 4.57286 16.5669 4.81694L5.31694 16.0669C5.07286 16.311 4.67714 16.311 4.43306 16.0669C4.18898 15.8229 4.18898 15.4271 4.43306 15.1831L15.6831 3.93306C15.9271 3.68898 16.3229 3.68898 16.5669 3.93306Z"
                  fill="#331E08"
                />
                <path
                  fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.43306 3.93306C4.67714 3.68898 5.07286 3.68898 5.31694 3.93306L16.5669 15.1831C16.811 15.4271 16.811 15.8229 16.5669 16.0669C16.3229 16.311 15.9271 16.311 15.6831 16.0669L4.43306 4.81694C4.18898 4.57286 4.18898 4.17714 4.43306 3.93306Z"
                  fill="#331E08"
                />
              </svg>
            </button>
          </div>
          <DashboardSettingAccount v-if="selectMenu === 'account'" />
          <DashboardSettingBilling v-else-if="selectMenu === 'billing'" />
          <div v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: Pretendard, 'Noto Sans KR', 'sans-serif' !important;
}

/* 모달 스타일을 추가하세요. */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000001;
}

.modal-content {
  background-color: white;
  padding: 0px;
  border-radius: 4px;
}

.SettingMenu {
  border-right: 1px solid #BEBEBE;
}

.SettingContent>.Top {
  border-bottom: 1px solid #FE9526;
}

.ButtonCancel {
  border-radius: 40px;
  border: 1px solid #1A1A1A;
}

.Dropdown {
  border-radius: 8px;
  border: 1px solid #B2B2B2;
  background: #FFF;
}

.border-custom-orange {
  border-color: #FE9526;
}
</style>
