export async function logout() {
  const localePath = useLocalePath()
  const supabase = useSupabaseClient()
  const router = useRouter()
  const { error } = await supabase.auth.signOut()
  if (error) {
    const err = new CodeError('[supabase error] supabase.auth.signOut', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    throw error
  }
  useCookie('sb-access-token').value = null
  useCookie('sb-refresh-token').value = null
  useCookie('sb-provider-token').value = null
  useCookie('sb-provider-refresh-token').value = null
  router.push(localePath('login'))
}
